// @ts-nocheck
import api from '@/api/user'
import AuthService from '@/service/oidcService'
import NProgress from 'nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'
import {EXIM1} from '../service/axios'
import store from '../store'
Vue.use(VueRouter)

const checkDataUser = async () => {
  let user = await AuthService._loadUser()
  const config = {
    headers: {
      Authorization: `Bearer ${user.access_token}`,
    },
  }
  let exim1 = await EXIM1.get(`/connect/userinfo`, config).catch((error) => {
    console.log('error', error)
  })
  let userStore = JSON.parse(JSON.stringify(store.state.user))
  let update = false
  if (userStore.name != exim1.data.firstName.toString()) {
    update = true
    userStore.name = exim1.data.firstName.toString()
  }

  if (userStore.surname != exim1.data.lastName.toString()) {
    update = true
    userStore.surname = exim1.data.lastName.toString()
  }

  if (userStore.mobilephone != exim1.data.phone.toString()) {
    update = true
    userStore.mobilephone = exim1.data.phone.toString()
  }

  if (update) {
    let member = []
    userStore.memberOf.forEach((data) => {
      member.push({number: data.memberOf, etc: data.memberOfEtc})
    })
    let res = await api.UpdateProfile(
      {...userStore, memberOf: member},
      store.state.token
    )
    if (res.data.success) {
      store.commit('SET_USER', {
        ...userStore,
      })
    }
  }
}

const ifAuthenticated = async (to, from, next) => {
  checkDataUser()
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null
  ) {
    next('/sign-in')
  } else {
    if (to.meta.userOnly && store.state.user.role != 0) {
      next('/not-allowed')
    } else {
      next()
    }
  }
}

const ifRecommentSatatus = async (to, from, next) => {
  checkDataUser()
  if (
    typeof store.state.token !== 'undefined' &&
    store.state.token !== null &&
    store.state.token !== '' &&
    store.state.user !== null
  ) {
    if (store.state.user.role == 60) {
      next('/user/list')
    } else if (store.state.user.role == 50) {
      next('/log-system')
    } else if (store.state.user.role == 40) {
      next('/refer')
    } else if (store.state.user.recommendStatus <= 1) {
      next()
    } else {
      next('/factor')
    }
  } else {
    next('/sign-in')
  }
}

const ifAuthenticatedInSignin = async (to, from, next) => {
  checkDataUser()
  if (
    typeof store.state.token !== 'undefined' &&
    store.state.token !== null &&
    store.state.token !== '' &&
    store.state.user !== null
  ) {
    if (store.state.user.role == 60) {
      next('/user/list')
    } else if (store.state.user.role == 50) {
      next('/log-system')
    } else if (store.state.user.role == 40) {
      next('/refer')
    } else if (store.state.user.recommendStatus <= 1) {
      next('/result')
    } else {
      next('/factor')
    }
  } else {
    next('/sign-in')
  }
}

const ifUserAssessment = (to, from, next) => {
  checkDataUser()
  if (
    typeof store.state.token !== 'undefined' &&
    store.state.token !== null &&
    store.state.token !== '' &&
    store.state.user !== null
  ) {
    if (store.state.user.role == 60) {
      next('/user/list')
    } else if (store.state.user.role == 50) {
      next('/log-system')
    } else if (store.state.user.role == 40) {
      next('/refer')
    } else if (store.state.user.recommendStatus <= 1) {
      next('/result')
    } else {
      next()
    }
  } else {
    next('/sign-in')
  }
}

const onlyUser = async (to, from, next) => {
  checkDataUser()
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null ||
    store.state.user.role !== 0
  ) {
    next('/not-allowed')
  } else {
    next()
  }
}

const onlyAdmin = (to, from, next) => {
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null ||
    store.state.user.role < 60
  ) {
    next('/not-allowed')
  } else {
    next()
  }
}

const onlySystemAdmin = (to, from, next) => {
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null ||
    store.state.user.role !== 50
  ) {
    next('/not-allowed')
  } else {
    next()
  }
}

const onlyTwoRoleAdmin = (to, from, next) => {
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null ||
    (store.state.user.role !== 60 && store.state.user.role !== 40)
  ) {
    next('/not-allowed')
  } else {
    next()
  }
}

const systemAdminUpTo = (to, from, next) => {
  if (
    typeof store.state.token === 'undefined' ||
    store.state.token === null ||
    store.state.token === '' ||
    store.state.user === null ||
    store.state.user.role < 40
  ) {
    next('/not-allowed')
  } else {
    next()
  }
}

const errorpage = (to, from, next) => {
  const error = new Error('Not Found')
  error.status = 404
  next('/404')
}

const routes = [
  {path: '*', component: () => import('@/views/ErrorScreen')},
  {
    path: '/',
    beforeEnter: ifAuthenticatedInSignin,
    // redirect: "/sign-in",
  },
  {
    path: '/latest/meta-data',
    beforeEnter: errorpage,
    redirect: '/404',
  },
  {
    path: '/._darcs',
    beforeEnter: errorpage,
    redirect: '/404',
  },
  {
    path: '/latest/meta-data/',
    beforeEnter: errorpage,
    redirect: '/404',
  },
  {
    path: '/._darcs/',
    beforeEnter: errorpage,
    redirect: '/404',
  },
  {
    path: '/sign-in',
    name: 'Signin',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/GetAccessToken'),
  },
  {
    path: '/admin/signin',
    name: 'SigninAdmin',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Signin'),
  },
  {
    path: '/sign-out',
    name: 'Signout',
    component: () => import('@/views/Signout'),
  },
  {
    path: '/sign-up',
    name: 'Signup',
    component: () => import('@/views/EXIM1/Signup'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('@/views/Maintenance'),
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: () => import('@/views/NotAllowed'),
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('@/views/Question'),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword'),
  },
  {
    path: '/reset-password/:token?',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword'),
  },
  {
    path: '/manage-account',
    name: 'ManageAccount',
    component: () => import('@/views/ManageAccount'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/admin-edit-profile',
    name: 'AdminEditProfile',
    component: () => import('@/views/AdminEditProfile'),
    beforeEnter: systemAdminUpTo,
  },
  {
    path: '/edit-company',
    name: 'EditCompany',
    component: () => import('@/views/EditCompany'),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/edit-company-profile/:id/:company',
    name: 'AdminEditCompanyProfile',
    component: () => import('@/views/EditCompany'),
    beforeEnter: onlyAdmin,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/empty-company',
    name: 'EmptyCompany',
    component: () => import('@/views/EmptyCompany'),
    // beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/views/ChangePassword'),
  },
  {
    path: '/reset-assessment',
    name: 'ResetAssessment',
    component: () => import('@/views/ConfirmReset'),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/factor',
    name: 'Factor',
    component: () => import('@/views/Factor'),
    beforeEnter: ifUserAssessment,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/level',
    name: 'Level',
    component: () => import('@/views/Level'),
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifUserAssessment,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/confirm-send',
    name: 'ComfirmSend',
    component: () => import('@/views/ComfirmSendForm'),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/send-success',
    name: 'SendSuccess',
    component: () => import('@/views/SendSuccess'),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/result-factor',
    name: 'ResultFactor',
    component: () => import('@/views/ResultFactor'),
    props: (route) => ({
      routeParams: route.query,
    }),
    beforeEnter: ifAuthenticated,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/ResultPreview'),
    beforeEnter: ifRecommentSatatus,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/export/result/:companyId?/:status?',
    name: 'ExportResult',
    component: () => import('@/views/ExportResult'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/edit-privacy',
    name: 'EditPrivacy',
    component: () => import('@/views/UserEditPrivacy'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/Notification'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/manage/answer',
    name: 'ManageAnswer',
    component: () => import('@/views/ManageAnswer'),
    beforeEnter: onlyAdmin,
    props: (route) => ({
      routeParams: route.query,
    }),
  },
  {
    path: '/list/answer',
    name: 'ListAnswer',
    component: () => import('@/views/ListAnswer'),
    beforeEnter: onlyAdmin,
    props: (route) => ({
      routeParams: route.query,
    }),
  },
  {
    path: '/user/list',
    name: 'UserList',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/UserList'),
  },
  {
    path: '/file-export',
    name: 'File Export',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/FileExport'),
  },
  {
    path: '/privacy/user',
    name: 'PrivacyUser',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/PrivacyUserList'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/Dashboard'),
  },
  {
    path: '/create-room',
    name: 'CreateRoom',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/Vote/CreateRoom'),
  },
  {
    path: '/lobby',
    name: 'Lobby',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/Vote/Lobby'),
  },
  {
    path: '/join-room/:id',
    name: 'JoinRoom',
    component: () => import('@/views/Vote/JoinRoom'),
  },
  {
    path: '/waiting-room',
    name: 'WaitingRoom',
    component: () => import('@/views/Vote/Waiting'),
  },
  {
    path: '/description',
    name: 'Description',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/ViewDescription'),
  },
  {
    path: '/vote-question',
    name: 'VoteQuestion',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/VoteQuestion'),
  },
  {
    path: '/waiting-answer',
    name: 'WaitingAnswer',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/WaitingAnswer'),
  },
  {
    path: '/vote-result',
    name: 'VoteResult',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/VoteResult'),
  },
  {
    path: '/slide',
    name: 'Slide',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/Slide'),
  },
  {
    path: '/testing',
    name: 'Testing',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/Testing'),
  },
  {
    path: '/answer',
    name: 'Answer',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/Answer'),
  },
  {
    path: '/rank-score',
    name: 'RankScore',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/RankScore'),
  },
  {
    path: '/reconnect',
    name: 'ReConnect',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/ReConnect'),
  },
  {
    path: '/total-score',
    name: 'TotalScore',
    props: (route) => ({
      routeParams: route.query,
    }),
    component: () => import('@/views/Vote/TotalScore'),
  },
  {
    path: '/dbd-request',
    name: 'DBDRequest',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/DBD/Request'),
  },
  {
    path: '/log-system',
    name: 'LogSystem',
    beforeEnter: onlySystemAdmin,
    component: () => import('@/views/LogSystem'),
  },
  {
    path: '/feedback/:from?',
    name: 'feedback user',
    beforeEnter: onlyUser,
    component: () => import('@/views/Feedback'),
  },
  {
    path: '/edit-user-profile/:id?/:companyId?',
    name: 'AdminEditCompany',
    component: () => import('@/views/AdminEditCompany'),
    beforeEnter: onlyAdmin,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/statement/:id?/:companyId',
    name: 'AdminViewStateMent',
    component: () => import('@/views/Statement'),
    beforeEnter: systemAdminUpTo,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/user-feedback/:tab?',
    name: 'userFeedBack',
    component: () => import('@/views/UserFeedback'),
    beforeEnter: onlyAdmin,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/view-feedback/:id/:companyId',
    name: 'view feedback',
    component: () => import('@/views/ViewFeedback'),
    beforeEnter: onlyAdmin,
    meta: {
      userOnly: true,
    },
  },
  {
    path: '/refer',
    name: 'refer',
    component: () => import('@/views/Refer'),
    beforeEnter: onlyTwoRoleAdmin,
  },
  {
    path: '/refer-user/:id',
    name: 'refer user',
    component: () => import('@/views/ReferUserList'),
    beforeEnter: onlyTwoRoleAdmin,
  },
  {
    path: '/product-detail/:type/:id',
    name: 'product detail',
    component: () => import('@/views/ProductDetail'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/changelog',
    name: 'ChangeLog',
    component: () => import('@/views/ChangeLog'),
  },

  {
    path: '/system/user',
    name: 'SystemUser',
    component: () => import('@/views/System/User'),
  },
  {
    path: '/system/user/profile/:id',
    name: 'SystemUser',
    component: () => import('@/views/System/UserProfile'),
  },
  {
    path: '/system/user/company/:id',
    name: 'SystemUser',
    component: () => import('@/views/System/UserCompany'),
  },
  {
    path: '/system/user/company/add/:id/:companyId?',
    name: 'SystemUserAdd',
    component: () => import('@/views/System/UserCompanyAdd'),
  },
  {
    path: '/system/user/company/edit/:id/:companyId',
    name: 'SystemUserEdit',
    component: () => import('@/views/System/UserCompanyEdit'),
  },
  {
    path: '/system/user/password/:id',
    name: 'SystemUserChangePassword',
    component: () => import('@/views/System/UserChangePassword'),
  },
  {
    path: '/system/admin',
    name: 'SystemAdmin',
    component: () => import('@/views/System/Admin'),
  },
  {
    path: '/system/admin/add',
    name: 'SystemAdminAdd',
    component: () => import('@/views/System/AdminAdd'),
  },
  {
    path: '/system/admin/edit/:id',
    name: 'SystemAdminEdit',
    component: () => import('@/views/System/AdminEdit'),
  },
  {
    path: '/manage/menu',
    name: 'ManageMenu',
    beforeEnter: onlyAdmin,
    component: () => import('@/views/ManageMenu'),
  },
  {
    path: '/signin-oidc',
    name: 'id4Dev',
    component: () => import('@/views/GetAccessToken'),
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/Callback'),
  },
  {
    path: '/signin/redirect',
    name: 'SigninRedirect',
    component: () => import('@/views/EXIM1/SigninRedirect'),
  },
  {
    path: '/user/profile',
    name: 'user profile',
    component: () => import('@/views/UserProfile'),
  },
  {
    path: '/silen/renew',
    name: 'silen renew',
    component: () => import('@/views/SilenRenew'),
  },
]

VueRouter.prototype.open = function(routeObject) {
  const {href} = this.resolve(routeObject)
  window.open(href, '_blank')
}

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  next()
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})

export default router
