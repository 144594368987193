import Vue from "vue";

Vue.mixin({
  methods: {
    addNewLine(value, num) {
      // let regx = /[ูุึํัี๊็้๋่ิื์]/g;
      // let count = 0;
      // let pool = 0;
      // let total = 0;
      // let text = "";
      // let arr = value.split("");
      // arr.map((item) => {
      //   // let newLine = "";
      //   // if (regx.test(item)) {
      //   //   console.log("item", item);
      //   //   pool++;
      //   // }
      //   // if (count == num) {
      //   //   console.log("pool", pool);
      //   //   total = num + pool;
      //   //   pool = 0;
      //   // }

      //   // if (count == total) {
      //   //   newLine = "\n";
      //   //   count = 0;
      //   //   total = 0;
      //   // }
      //   // text += newLine + item;
      //   // count++;
      // });

      let text = value
        .replace(" ", "\n")
        .replace("และ", "และ\n")
        .replace("บวน", "บวน\n")
        .replace("ลอก", "\nลอก")
        .replace("การเงิน", "\nการเงิน")
        .replace("การบริหารการ", "การบริหาร\nการ")
        .replace("การพัฒนา", "การพัฒนา\n")
        .replace("สำรวจกลุ่มลูกค้า", "สำรวจ\nกลุ่มลูกค้า")
        .replace("ทางตลาด", "\nทางตลาด")
        .replace("ความเสี่ยง", "\nความเสี่ยง")
        .replace("Ex", "\nEx");
      return text;
    },
  },
});
