import { HTTP } from "@/service/axios";

export default {
  UpdateProfile(obj, token) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-TOKEN": "",
          Authorization: `Bearer ${token}`,
        },
      };
      let formData = new FormData();
      formData.append("userId", obj.id);

      let profile =
        obj.profileImg && typeof obj.profileImg.raw != "undefined"
          ? obj.profileImg.name
          : obj.profileImg
          ? obj.profileImg
          : "";
      formData.append("profileImg", profile);
      if (obj.profileImg && typeof obj.profileImg.raw != "undefined") {
        formData.append("file", obj.profileImg.raw);
      } else {
        formData.append("file", "1");
      }

      formData.append(
        "email",
        typeof obj.email != "undefined" ? obj.email : ""
      );
      formData.append(
        "password",
        typeof obj.password != "undefined" ? obj.password : ""
      );
      formData.append(
        "titleName",
        typeof obj.titleName != "undefined" ? obj.titleName : 0
      );
      formData.append("name", typeof obj.name != "undefined" ? obj.name : "");
      formData.append(
        "surname",
        typeof obj.surname != "undefined" ? obj.surname : ""
      );
      formData.append(
        "gender",
        typeof obj.gender != "undefined" ? obj.gender : 0
      );
      formData.append("age", typeof obj.age != "undefined" ? obj.age : 0);
      formData.append(
        "education",
        typeof obj.education != "undefined" ? obj.education : 0
      );
      formData.append(
        "mobilephone",
        typeof obj.mobilephone != "undefined" ? obj.mobilephone : ""
      );
      formData.append(
        "companyName",
        typeof obj.companyName != "undefined" ? obj.companyName : ""
      );
      formData.append(
        "corporateNumber",
        typeof obj.corporateNumber != "undefined" ? obj.corporateNumber : ""
      );
      formData.append(
        "position",
        typeof obj.position != "undefined" ? obj.position : 0
      );
      formData.append(
        "businessType",
        typeof obj.businessType != "undefined" ? obj.businessType : 0
      );
      formData.append(
        "cateIndustryType",
        typeof obj.cateIndustryType != "undefined" &&
          obj.cateIndustryType != null &&
          obj.cateIndustryType != ""
          ? obj.cateIndustryType
          : 0
      );
      formData.append(
        "industryType",
        typeof obj.industryType != "undefined" &&
          obj.industryType != null &&
          obj.industryType != ""
          ? obj.industryType
          : 0
      );
      formData.append(
        "industryTypeEtc",
        typeof obj.industryTypeEtc != "undefined" ? obj.industryTypeEtc : ""
      );
      formData.append(
        "memberOf",
        typeof obj.memberOf != "undefined" ? JSON.stringify(obj.memberOf) : ""
      );
      formData.append(
        "memberOfEtc",
        typeof obj.memberOfEtc != "undefined" ? obj.memberOfEtc : ""
      );
      formData.append(
        "employment",
        typeof obj.employment != "undefined" ? obj.employment : 0
      );
      formData.append(
        "yearEstablished",
        typeof obj.yearEstablished != "undefined" ? obj.yearEstablished : ""
      );
      formData.append(
        "latestAnnualRevenues",
        typeof obj.latestAnnualRevenues != "undefined"
          ? obj.latestAnnualRevenues
          : 0
      );
      formData.append(
        "latestAnnualProfits",
        typeof obj.latestAnnualProfits != "undefined"
          ? obj.latestAnnualProfits
          : 0
      );
      formData.append(
        "exportExperience",
        typeof obj.exportExperience != "undefined" &&
          obj.exportExperience != null &&
          obj.exportExperience != ""
          ? obj.exportExperience
          : 0
      );
      formData.append(
        "exportRatio",
        typeof obj.exportRatio != "undefined" &&
          obj.exportRatio != null &&
          obj.exportRatio != ""
          ? obj.exportRatio
          : 0
      );
      formData.append(
        "exportSales",
        typeof obj.exportSales != "undefined" &&
          obj.exportSales != null &&
          obj.exportSales != ""
          ? obj.exportSales
          : 0
      );
      formData.append(
        "orderConsistency",
        typeof obj.orderConsistency != "undefined" &&
          obj.orderConsistency != null &&
          obj.orderConsistency != ""
          ? obj.orderConsistency
          : 0
      );
      formData.append(
        "exportFrequency",
        typeof obj.exportFrequency != "undefined" &&
          obj.exportFrequency != null &&
          obj.exportFrequency != ""
          ? obj.exportFrequency
          : 0
      );
      formData.append(
        "exportCountry",
        typeof obj.exportCountry != "undefined" &&
          obj.exportCountry != null &&
          obj.exportCountry != ""
          ? obj.exportCountry
          : 0
      );
      formData.append(
        "trainingExpectations",
        typeof obj.trainingExpectations != "undefined" &&
          obj.trainingExpectations != null &&
          obj.trainingExpectations != ""
          ? obj.trainingExpectations
          : 0
      );
      formData.append(
        "moreExportExpectations",
        typeof obj.moreExportExpectations != "undefined" &&
          obj.moreExportExpectations != null &&
          obj.moreExportExpectations != ""
          ? obj.moreExportExpectations
          : 0
      );
      formData.append(
        "expectedPercentage",
        typeof obj.expectedPercentage != "undefined" &&
          obj.expectedPercentage != null &&
          obj.expectedPercentage != ""
          ? obj.expectedPercentage
          : 0
      );
      formData.append(
        "expectedPercentageEtc",
        typeof obj.expectedPercentageEtc != "undefined" &&
          obj.expectedPercentageEtc != "" &&
          obj.expectedPercentageEtc != null
          ? obj.expectedPercentageEtc
          : 0
      );
      formData.append(
        "consent",
        typeof obj.consent != "undefined" ? obj.consent : 0
      );
      formData.append(
        "companyNameEdit",
        typeof obj.companyNameEdit != "undefined" &&
          obj.companyNameEdit != null &&
          obj.companyNameEdit != ""
          ? obj.companyNameEdit
          : ""
      );
      formData.append(
        "operatorType",
        typeof obj.operatorType != "undefined" &&
          obj.operatorType != null &&
          obj.operatorType != ""
          ? obj.operatorType
          : 0
      );
      formData.append(
        "exportPlan",
        typeof obj.exportPlan != "undefined" &&
          obj.exportPlan != "" &&
          obj.exportPlan != null
          ? obj.exportPlan
          : 0
      );
      formData.append(
        "isProduction",
        typeof obj.isProduction != "undefined" ? obj.isProduction : ""
      );
      formData.append(
        "shipment",
        typeof obj.shipment != "undefined" &&
          obj.shipment != "" &&
          obj.shipment != null
          ? obj.shipment
          : 0
      );
      formData.append(
        "paymentTerm",
        typeof obj.paymentTerm != "undefined" &&
          obj.paymentTerm != "" &&
          obj.paymentTerm != null
          ? obj.paymentTerm
          : 0
      );

      formData.append(
        "exportValue",
        typeof obj.exportValue != "undefined" &&
          obj.exportValue != "" &&
          obj.exportValue != null
          ? obj.exportValue
          : 0
      );

      formData.append("_csrf", "");
      HTTP.put(`user`, formData, config)
        .then((response) => {
          const result = response;
          return resolve(result);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  },
};
