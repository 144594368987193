// @ts-nocheck
import Vue from "vue";
import {
  Steps,
  Step,
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimePicker,
  Popover,
  Tooltip,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Alert,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Card,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Container,
  Header,
  Main,
  Footer,
  Divider,
  Loading,
  MessageBox,
  Message,
  Notification,
  Badge,
  Rate,
} from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";

import "@/styles/index.scss";
import "@/service/alert";
import "@/service/chart";
import "@/service/version";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMask from "v-mask";
import locale from "element-ui/lib/locale";
import lang from "element-ui/lib/locale/lang/en";
import VueDataTables from "vue-data-tables";
import numeral from "numeral";

import Auth from "@/service/Auth";
import mixinState from "@/service/mixinState";
import YimoVueEditor from "yimo-vue-editor";
import moment from "moment";
import VueCryptojs from "vue-cryptojs";
import VueNumeric from "vue-numeric";
import VueCsrf from "vue-csrf";
import "nprogress/nprogress.css";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css";
import momentTH from "moment/src/locale/th";

import Quill from "quill";
const quillTable = require("quill-table");
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);

// import VueHtml2Canvas from 'vue-html2canvas';

// Vue.use(VueHtml2Canvas);
Vue.use(VueCsrf);

import Vqr from "v-qr";
Vue.use(Vqr);

Vue.filter("dotRoundDown", (value) => {
  let num = value.toString();
  let dot = num.indexOf(".");
  if (dot > -1) {
    let n1 = num.substring(0, dot);
    let d1 = num.substring(dot, dot + 2);
    let convert = Number(n1 + d1);
    return convert;
  } else {
    return value.toFixed(1);
  }
  // let num = value.toString();
  // return parseFloat(num).toFixed(1);
});

Vue.filter("comma", (value) => {
  return numeral(value).format("0,0");
});
Vue.filter("commaValue", (value) => {
  // console.log(value, "valuevalue");
  if (value == "-") {
    return "-";
  } else if (value == "N/A") {
    return "N/A";
  } else if (value == "nu") {
    return "N/A";
  } else {
    return numeral(value).format("0,0.00");
  }
});

Vue.filter("numberCurrency", (value) => {
  return numeral(value).format("0 a");
});

Vue.prototype.moment = moment;
moment.locale("th");

Vue.filter("dashformate", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "DD-MM-yyyy"
    );
  }
  return res;
});
Vue.filter("dateNormal", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date)).format("DD/MM/yyyy");
  }
  return res;
});

Vue.filter("dateTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "ll"
    );
  }
  return res;
});
Vue.filter("dateTimeTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH).add(543, "y")).format(
      "lll น."
    );
  }
  return res;
});
Vue.filter("yearTh", (date) => {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).add(543, "y")).format("YYYY");
  }
  return res;
});

Vue.filter("rangeFromNow", function (date) {
  let res = "";
  if (typeof date === "undefined" || date === null || date === "") {
    res = "-";
  } else {
    res = moment(moment(date).locale("th", momentTH)).fromNow();
  }
  return res;
});

Vue.filter("striphtml", function (value) {
  value = value
    .replace(/<\/p>/gi, "</p> ")
    .replace(/<\/span>/gi, "</span> ")
    .replace(/<\/h/gi, " </h");
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});

Vue.mixin(mixinState);
Vue.mixin(Auth);

Vue.use(VueNumeric);
Vue.use(VueCryptojs);
Vue.use(YimoVueEditor, {
  uploadHandler: (type, resTxt) => {
    //Upload processing hook
    if (type === "success") {
      var res = JSON.parse(resTxt); //Do not process the default look at the return value bit image path
      return process.env.VUE_APP_BASE_URL_API + res;
    } else if (type === "error") {
      //todo toast
    } else if (type === "timeout") {
      //todo toast
    }
    return "upload failed__";
  },
});
locale.use(lang);

Vue.use(VueMask);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Alert);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Divider);
Vue.use(Rate);
Vue.use(Badge);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.use(VueDataTables);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
