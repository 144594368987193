export default [
  {
    id: 1,
    label: "ของขวัญ / ของเล่น",
    subIndustry: [
      { value: 570, label: "ของเล่นและผลิตภัณฑ์สำหรับเด็ก" },
      { value: 521, label: "ของขวัญ ของชําร่วยและของตกแต่งบ้าน" },
    ],
  },
  {
    id: 2,
    label: "ผลิตภัณฑ์สำหรับสัตว์เลี้ยง",
    subIndustry: [
      { value: 330, label: "อาหารสัตว์เลี้ยง" },
      { value: 542, label: "ผลิตภัณฑ์สำหรับสัตว์เลี้ยง" },
    ],
  },
  {
    id: 3,
    label: "ผลิตภัณฑ์และผลิตผลทางการเกษตร / ผัก / ผลไม้/ ปศุสัตว์",
    subIndustry: [
      { value: 230, label: "แป้งมันสําปะหลัง" },
      { value: 231, label: "กาแฟ" },
      { value: 111, label: "ข้าว" },
      { value: 112, label: "ข้าวโพดและพืชพันธุ์ไทย" },
      { value: 260, label: "นํ้าตาล" },
      { value: 261, label: "นํ้ามันถั่วเหลืองและนํ้ามันรําข้าว" },
      { value: 310, label: "น้ำมันปาล์ม" },
      { value: 311, label: "ปลาทูน่า" },
      { value: 114, label: "ผักและผลไม้ไทย" },
      { value: 113, label: "มันสําปะหลัง" },
      { value: 116, label: "สับปะรด" },
      { value: 131, label: "กุ้ง" },
      { value: 141, label: "ไก่" },
      { value: 142, label: "สุกร" },
    ],
  },
  {
    id: 4,
    label: "พลาสติก / บรรจุภัณฑ์พลาสติก",
    subIndustry: [
      { value: 561, label: "พลาสติก" },
      { value: 645, label: "พลาสติกชีวภาพ" },
    ],
  },
  {
    id: 5,
    label: "ยา / เวชภัณฑ์ / เครื่องสำอาง / เครื่องมือแพทย์",
    subIndustry: [
      { value: 561, label: "ยา" },
      { value: 562, label: "เครื่องมือแพทย์และสุขภาพ" },
      { value: 563, label: "ผลิตภัณฑ์เสริมอาหาร" },
      { value: 564, label: "เครื่องสำอาง" },
    ],
  },
  {
    id: 6,
    label: "ยางและผลิตภัณฑ์ยาง",
    subIndustry: [
      { value: 240, label: "ถุงมือยาง" },
      { value: 241, label: "ยางรถยนต์" },
      { value: 115, label: "ผลิตภัณฑ์จากยาง" },
      { value: 118, label: "ยางพาราและน้ำยางข้น" },
    ],
  },
  {
    id: 7,
    label: "ยานยนต์และชิ้นส่วนอะไหล่ยนต์",
    subIndustry: [
      { value: 841, label: "ยานยนต์" },
      { value: 844, label: "ชิ้นส่วนและอะไหล่ยานยนต์" },
    ],
  },
  {
    id: 8,
    label: "วัสดุก่อสร้าง / หลังคาและกระเบื้อง / เครื่องมือและอุปกรณ์",
    subIndustry: [
      { value: 671, label: "แกรนิตและหินอ่อน" },
      { value: 672, label: "คอนกรีต" },
      { value: 670, label: "ปูนซีเมนต์" },
      { value: 673, label: "สีทาบ้าน" },
      { value: 674, label: "หลังคาและกระเบื้อง" },
      { value: 1650, label: "วัสดุก่อสร้าง เครื่องมือและอุปกรณ์ก่อสร้างอื่นๆ" },
    ],
  },
  {
    id: 9,
    label: "สินค้าอุปโภค/บริโภค",
    subIndustry: [{ value: 1700, label: "สินค้าอุปโภค/บริโภค" }],
  },
  {
    id: 10,
    label: "สิ่งทอและเครื่องนุ่งห่ม",
    subIndustry: [
      { value: 512, label: "เครื่องนุ่งห่ม" },
      { value: 511, label: "เสื้อผ้าสำเร็จรูปและเครื่องแต่งกาย" },
      { value: 510, label: "สิ่งทอ" },
      { value: 515, label: "ฟอกย้อมพิมพ์และตกแต่งสิ่งทอ" },
    ],
  },
  {
    id: 11,
    label: "สิ่งพิมพ์ / กระดาษ / บรรจุภัณฑ์กระดาษ",
    subIndustry: [
      { value: 533, label: "การพิมพ์และสิ่งพิมพ์" },
      { value: 532, label: "บรรจุภัณฑ์กระดาษ" },
      { value: 531, label: "เยื่อและกระดาษ" },
    ],
  },
  {
    id: 12,
    label: "อาหาร / เครื่องดื่ม",
    subIndustry: [
      { value: 142, label: "อาหารแช่แข็ง" },
      { value: 290, label: "อาหารสำเร็จรูป" },
      { value: 360, label: "อาหารทารก และเด็กเล็ก" },
      { value: 361, label: "ปลาป่น" },
      { value: 117, label: "สมุนไพร" },
      { value: 340, label: "เครื่องดื่ม" },
      { value: 119, label: "เครื่องปรุงรสและเครื่องเทศ" },
      { value: 121, label: "อาหารอื่นๆ" },
    ],
  },
  {
    id: 13,
    label: "อุปกรณ์กีฬา",
    subIndustry: [{ value: 580, label: "อุปกรณ์กีฬา" }],
  },
  {
    id: 14,
    label: "เคมีภัณฑ์ / ปิโตรเคมี",
    subIndustry: [
      { value: 610, label: "เคมีภัณฑ์" },
      { value: 630, label: "ปิโตรเคมี" },
    ],
  },
  {
    id: 15,
    label: "เครื่องครัวและเครื่องใช้ในบ้านเรือน",
    subIndustry: [{ value: 653, label: "เครื่องครัวและเครื่องใช้ในบ้านเรือน" }],
  },
  {
    id: 16,
    label: "เครื่องจักรกลและอุปกรณ์",
    subIndustry: [
      { value: 832, label: "เครื่องจักรกลการเกษตร" },
      { value: 831, label: "เครื่องจักรกลและโลหะการ" },
      { value: 830, label: "เครื่องจักรกลและอุปกรณ์อื่นๆ" },
    ],
  },
  {
    id: 17,
    label: "เครื่องประดับและอัญมณี",
    subIndustry: [{ value: 550, label: "เครื่องประดับและอัญมณี" }],
  },
  {
    id: 18,
    label: "หนังและผลิตภัณฑ์หนัง",
    subIndustry: [
      { value: 545, label: "การฟอกหนัง" },
      { value: 544, label: "รองเท้า กระเป๋า และเครื่องหนัง" },
    ],
  },
  {
    id: 19,
    label: "เครื่องเขียน / เครื่องใช้สำนักงาน",
    subIndustry: [{ value: 1701, label: "เครื่องเขียน / เครื่องใช้สำนักงาน" }],
  },
  {
    id: 20,
    label: "เครื่องใช้ไฟฟ้า / อุปกรณ์อิเล็กทรอนิคส์ / ซอฟท์แวร์",
    subIndustry: [
      { value: 710, label: "คอมพิวเตอร์" },
      { value: 750, label: "เครื่องทําความเย็น" },
      { value: 752, label: "เครื่องปรับอากาศและเครื่องทำความเย็น" },
      { value: 780, label: "เครื่องใช้ไฟฟ้าและชิ้นส่วนอิเล็กทรอนิคส์" },
      { value: 781, label: "ซอฟท์แวร์และดิจิทัล" },
    ],
  },
  {
    id: 21,
    label: "เซรามิค / แก้ว / เครื่องสุขภัณฑ์",
    subIndustry: [
      { value: 650, label: "เซรามิค" },
      { value: 660, label: "แก้วและกระจก" },
      { value: 651, label: "เครื่องสุขภัณฑ์" },
    ],
  },
  {
    id: 22,
    label: "เฟอร์นิเจอร์และของตกแต่งบ้าน",
    subIndustry: [
      { value: 521, label: "เฟอร์นิเจอร์" },
      { value: 522, label: "สินค้าตกแต่งบ้านและผลิตภัณฑ์ไลฟ์สไตล์" },
    ],
  },
  {
    id: 23,
    label: "โรงกลั่นปิโตรเลียม / ก๊าซ / พลังงาน / แร่ธาตุ",
    subIndustry: [
      { value: 780, label: "ไฟฟ้า" },
      { value: 460, label: "กลั่นน้ำมันปิโตรเลียม" },
      { value: 461, label: "ก๊าซ" },
      { value: 1020, label: "ชีวพลังงาน" },
      { value: 1021, label: "พลังงานหมุนเวียน" },
    ],
  },
  {
    id: 24,
    label: "โลหะภัณฑ์",
    subIndustry: [
      { value: 821, label: "เหล็ก" },
      { value: 812, label: "เหล็กแผ่น" },
      { value: 813, label: "ต่อเรือซ่อมเรือและก่อสร้างงานเหล็ก" },
      { value: 814, label: "อลูมีเนียม" },
      { value: 822, label: "หล่อโลหะ และโลหะอื่นๆ" },
    ],
  },
  {
    id: 25,
    label: "ไม้และผลิตภัณฑ์จากไม้",
    subIndustry: [
      { value: 122, label: "ไม้ยางพารา" },
      { value: 123, label: "ไม้อัดไม้บางและวัสดุแผ่น" },
      { value: 120, label: "ไม้และผลิตภัณฑ์จากไม้อื่นๆ" },
    ],
  }, {
    //รันเลขใหม่ในหมวดหมู่เดียวกัน สาธารณูปโภค (ผลิตไฟฟ้า / สถานบริการน้ำมัน / แก๊ส / ประปา) รหัสธุรกิจ 1660
    id: 29,
    label: "สาธารณูปโภค (ผลิตไฟฟ้า / สถานบริการน้ำมัน / แก๊ส / ประปา)",
    subIndustry: [
      { value: 1661, label: "ระบบคมนาคม" },
      { value: 1662, label: "ระบบจัดการน้ำ" },
      { value: 1663, label: "ระบบสาธารณูปโภค" },
      { value: 1664, label: "ระบบกำจัดขยะ" },
    ],
  },
  {
    id: 30,
    label: "การคมนาคมและสื่อสาร",
    subIndustry: [
      { value: 1670, label: "การคมนาคมและสื่อสาร" },
    ],
  },
  {
    //รันเลขใหม่ในหมวดหมู่เดียวกัน ไฟฟ้าและแสงสว่าง  และไฟฟ้าพลังงาน รหัสธุรกิจ 1020
    id: 31,
    label: "พลังงาน / ไฟฟ้า / แสงสว่าง",
    subIndustry: [
      { value: 1023, label: "พลังงานทดแทน" },
      { value: 1022, label: "พลังงานไฟฟ้า" },
      { value: 1021, label: "แสงสว่าง" },
    ],
  },
  {
    //รันเลขใหม่ในหมวดหมู่เดียวกัน พาณิชย์นาวี รหัสธุรกิจ 1110
    id: 32,
    label: "โลจิสติกส์ / พาณิชย์นาวี",
    subIndustry: [
      { value: 1111, label: "โลจิสติกส์" },
      { value: 1110, label: "พาณิชย์นาวี" },
    ],
  },
  {
    //รันเลขใหม่ตามเอกสาร change request ในเอกสารประเภทอุตสาหกรรมไม่มีหมวดนี้อยู่ รหัสธุรกิจ 1450
    id: 33,
    label: "การศึกษา / สาธารณสุข",
    subIndustry: [
      { value: 1451, label: "การศึกษา" },
      { value: 1452, label: "สาธารณสุข" },
    ],
  },
  {
    id: 26,
    label: "บริการ",
    subIndustry: [{ value: 0, label: "อื่นๆ" }],
  },
  {
    id: 27,
    label: "ซื้อมาขายไป",
    subIndustry: [{ value: 0, label: "อื่นๆ" }],
  },
  {
    id: 28,
    label: "อื่นๆ",
    subIndustry: [{ value: 0, label: "อื่นๆ" }],
  },
];
