// @ts-nocheck

import { HTTP } from "@/service/axios.js";
import "@/service/Auth";
export default {
  data() {
    return {
      loadingPage: true,
      stateNow: "",
    };
  },
  computed: {
    // csrfToken(){
    //     return this.$store.state.csrfToken
    // },
    step() {
      return this.$store.state.step;
    },
    user() {
      return this.$store.state.user;
    },
    room() {
      return this.$store.state.room;
    },
    token() {
      return this.$store.state.token;
    },
    allExam() {
      return this.$store.state.exam;
    },
  },
  methods: {
    checkStep() {
      if (this.step.state != this.stateNow.state) {
        this.checkRouterUser(this.step.state);
      }
    },
    async getStateNow() {
      try {
        let obj = this.room.roomId;
        HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
        let res = await HTTP.get(`state/${obj}`)
          .then((result) => {
            this.$store.commit("SET_STEP", result.data.obj);
          })
          .catch((err) => {});
        if (res.data.success) {
          if (res.data.obj.state != this.step.state) {
            await this.checkRouterUser(res.data.obj.state);
          }
          this.stateNow = res.data.obj;
        }
      } catch {
        console.log(`getStateNow error`);
      }
    },
    async checkRouterUser(data) {
      if (data == "description") {
        this.$router.push("/description").catch((err) => {});
      } else if (data == "test") {
        this.$router.push("/vote-question").catch((err) => {});
      } else if (data == "waiting answer") {
        this.$router.push("/waiting-answer").catch((err) => {});
      } else if (data == "answer") {
        this.$router.push("/vote-result").catch((err) => {});
      } else if (data == "rank") {
        this.$router.push("/total-score").catch((err) => {});
      } else if (data == "end") {
        if (this.token != "") {
          let res = await this.getUserById(this.token);
          if (res.data.success) {
            this.$store.commit(
              "SET_USER",
              JSON.parse(JSON.stringify(res.data.obj))
            );
          }
          if (this.user.role == 0) {
            this.$router.push("/").catch((err) => {});
          }
        } else {
          this.$router.push("/sign-in").catch((err) => {});
        }
      }
    },
    setState ( obj )
    {
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`state`, obj).then((res) => {
        this.getState();
      });
    },
    async getState() {
      let obj = this.room.roomId;
      let res = await HTTP.get(`state/${obj}`);
      if (res.data.success) {
        let obj = {
          attId: res.data.obj.attId,
          id: res.data.obj.id,
          qId:
            res.data.obj.qId != "undefined"
              ? res.data.obj.qId
              : res.data.obj.qid,
          roomId: res.data.obj.roomId,
          state: res.data.obj.state,
          total: res.data.obj.total,
        };
        await this.$store.commit("SET_STEP", obj);
        if (obj.state == "end") {
          this.checkRouterUser(obj.state);
        }
        this.loadingPage = false;
      }
      this.loadingPage = false;
    },
    async getRoom() {
      HTTP.get(`room/${this.room.code}`)
        .then(async (result) => {
          if (result.data.success) {
            this.$store.commit("SET_ROOM", result.data.obj);
            if (typeof this.user.role == "undefined") {
              // || this.user.role == 0
              let user = result.data.obj.players.filter(
                (a) => a.id == this.user.id
              );
              if (user.length == 0) {
                this.$notify.error({
                  title: "ขออภัย",
                  message: "คุณถูกเชิญออกจากห้อง",
                });
                if (this.token != "") {
                  let res = await this.getUserById(this.token);
                  if (res.data.success) {
                    this.$store.commit(
                      "SET_USER",
                      JSON.parse(JSON.stringify(res.data.obj))
                    );
                  }
                }
                this.$router.push("/");
              }
            }
          }
        })
        .finally(() => {
          this.loadingPage = false;
        })
        .catch((err) => {});
    },
  },
};
