import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 2000 * 60,
});

export const EXIM1 = axios.create({
  baseURL: process.env.VUE_APP_OIDC_SERVE,
  timeout: 2000 * 60,
});
