export default [
    {
        no:1,
        step:[
            {
                type:'video',
                file:['Q1_1VDO_Slide5.mp4']
            },
        ],
        question:'1. นาย X ควรจะเริ่มต้นและเตรียมตัวอย่างไรดี?',
        choice:[
            'จ้างเอเจนซี่ทำโฆษณาออนไลน์เพื่อหาผู้นำเข้าหรือ ตัวแทนจำหน่ายในประเทศเป้าหมาย',
            'จดทะเบียนการค้าแบบนิติบุคคล จดทะเบียนภาษีมูลค่าเพิ่ม และทำบัตรประจำตัวผู้ส่งออก',
            'ส่ง E-mail และโทรเชิญผู้ซื้อที่ค้นหาในอินเตอร์เน็ตให้มาเจรจาการค้าที่ประเทศไทย'
        ],
        picAnswer:{
            q1:'Q1_A1_Slide8.jpg',
            q2:'Q1_A2_Slide9.jpg',
            q3:'Q1_A3_Slide10.jpg'
        },
        answer:2
    },
    {
        no:2,
        step:[
            {
                type:'file',
                file:['Q2_1Slide_Slide11.jpg']
            },
            {
                type:'video',
                file:['Q2_2VDO_Slide12.mp4']
            },
        ],
        question:'2. นาย X ควรจะหาผู้ซื้อด้วยวิธีใดดีที่สุด',
        choice:[
            'บนบานกับสิ่งศักดิ์สิทธิ์ขอให้มีลูกค้าเข้ามาติดต่อ',
            'ทำ website และลงโฆษณาใน social media ทั้ง Facebook, Instagram, Twitter และ Youtube',
            'หาทางร่วมสำรวจตลาด ออกงาน Exhibition, Trade Show, Business Matching ต่างๆ'
        ],
        picAnswer:{
            q1:'Q2_A1_Slide15.jpg',
            q2:'Q2_A2_Slide16.jpg',
            q3:'Q2_A3_Slide17.jpg'
        },
        answer:3
    },
    {
        no:3,
        step:[
            {
                type:'file',
                file:['Q3_1Slide_Slide18.jpg']
            },
            {
                type:'video',
                file:['Q3_2VDO_Slide19.mp4']
            },
        ],
        picAnswer:{
            q1:'Q3_A1_Slide22.jpg',
            q2:'Q3_A2_Slide23.jpg',
            q3:'Q3_A3_Slide24.jpg'
        },
        question:'3. นาย X จะคำนวณ ราคา FOB อย่างไร',
        choice:[
            'เอาค่าใช้จ่ายในการส่งสินค้าไปท่าเรือต้นทางมาคิดเฉลี่ยต่อชิ้น แล้วนำไปบวกเข้าไปในราคาขายต่อหน่วย (Unit Price)',
            'เอาค่าใช้จ่ายในการส่งสินค้าไปท่าเรือปลายทางมาคิดเฉลี่ยต่อชิ้น แล้วนำไปบวกเข้าไปในราคาขายต่อหน่วย (Unit Price)',
            'เอาค่าใช้จ่ายในการส่งสินค้าไปยังโกดังผู้ซื้อมาคิดเฉลี่ยต่อชิ้น แล้วนำไปบวกเข้าไปในราคาขายต่อหน่วย (Unit Price)'
        ],
        answer:1
    },
    {
        no:4,
        step:[
            {
                type:'video',
                file:['Q4_1VDO_Slide25.mp4']
            },
            {
                type:'video',
                file:['Q4_2VDO_Slide26.mp4']
            },
        ],
        picAnswer:{
            q1:'Q4_A1_Slide29.jpg',
            q2:'Q4_A2_Slide30.jpg',
            q3:'Q4_A3_Slide31.jpg'
        },
        question:'4. นาย X ควรตอบกลับ Mr. Chen อย่างไร',
        choice:[
            'ยอมรับข้อเสนอของ Mr.Chen แม้รู้ว่าจะทำให้สภาพคล่องของกิจการลดลงเป็นอย่างมาก',
            'ตรวจสอบประวัติผู้ซื้อ และขอเจรจาเปลี่ยน เทอมการชำระเงิน',
            'ปฏิเสธที่จะทำการค้ากับ Mr. Chen เพราะเทอมการชำระเงินที่เสนอมาเอาเปรียบเกินไป'

        ],
        answer:2
    },
    {
        no:5,
        step:[
            {
                type:'video',
                file:['Q5_1VDO_Slide32.mp4']
            },
            {
                type:'file',
                file:['Q5_2Slide1_Slide33.jpg','Q5_2Slide2_Slide34.jpg','Q5_2Slide3_Slide35.jpg']
            },
            {
                type:'video',
                file:['Q5_3VDO_Slide36.mp4']
            },
        ],
        question:'5. นาย X ควรทำอย่างไร เมื่อได้รับ L/C',
        choice:[
            'รับ L/C แล้วรีบผลิตสินค้าจะได้รีบส่งให้ Mr.Chen เร็วๆ',
            'รับ L/C พร้อมยื่นขอสินเชื่อ Packing Credit กับ EXIM BANK',
            'ตรวจสอบความถูกต้องของ L/C ว่าตรงกับที่ได้เจรจาหรือไม่ พร้อมตรวจสอบว่าสามารถส่งเอกสารได้ตามที่ระบุไว้ใน L/C หรือเปล่า'
        ],
        picAnswer:{
            q1:'Q5_A1_Slide39.jpg',
            q2:'Q5_A2_Slide40.jpg',
            q3:'Q5_A3_Slide41.jpg'
        },
        answer:3
    },
    {
        no:6,
        step:[
            {
                type:'video',
                file:['Q6_1VDO_Slide42.mp4']
            },
            {
                type:'file',
                file:['Q6_2Slide_Slide43.jpg']
            },
            {
                type:'video',
                file:['Q6_3VDO_Slide44.mp4']
            },
        ],
        question:'6. นาย X ควรแก้ปัญหาเรื่องสภาพคล่องอย่างไร',
        choice:[
            'นำเงินกู้ที่ขอกู้มาเพื่อขยายโรงงานมาหมุนซื้อวัตถุดิบ ถึงจะทำให้การสร้างโรงงานเสร็จช้ากว่ากำหนดก็ตาม',
            'ใช้เงินเก็บของบริษัทที่มีอยู่ แม้ว่าจะส่งผลกระทบเรื่องสภาพคล่องของบริษัท ส่วนอัตราแลกเปลี่ยนก็ไม่น่าเปลี่ยนแปลง',
            'เสริมสภาพคล่องด้วยการใช้บริการรับซื้อตั๋วส่งออก และทำ Forward Contract เพื่อปิดความเสี่ยงด้านอัตราแลกเปลี่ยน'
        ],
        picAnswer:{
            q1:'Q6_A1_Slide47.jpg',
            q2:'Q6_A2_Slide48.jpg',
            q3:'Q6_A3_Slide49.jpg'
        },
        answer:3
    },
    {
        no:7,
        step:[
            {
                type:'video',
                file:['Q7_1VDO_Slide50.mp4']
            },
            {
                type:'video',
                file:['Q7_2VDO_Slide51.mp4']
            },
            {
                type:'file',
                file:['Q7_3Slide_Slide52.jpg','Q7_4Quiz_Slide53.jpg']
            }
        ],
        question:'7. วิธีการชำระเงินในข้อใดมีความเสี่ยงสูงที่สุด',
        choice:[
            'L/C Term 90 วัน',
            'D/P Term 60 วัน',
            'O/A 30 วัน'
        ],
        picAnswer:{
            q1:'',
            q2:'',
            q3:'popup7.png'
        },
        answer:3
    },
    {
        no:8,
        step:[],
        question:'8. ในการชำระเงินด้วยวิธีการชำระเงินแบบ L/C ผู้ขายจะต้องได้รับแจ้ง L/C จากใคร',
        choice:[
            'ธนาคารที่ทำการเปิด L/C ให้แก่ผู้ขาย',
            'ธนาคารตัวแทนของผู้ขาย',
            'ผู้ซื้อ'
        ],
        picAnswer:{
            q1:'',
            q2:'popup8.png',
            q3:''
        },
        answer:2
    },
    {
        no:9,
        step:[],
        question:'9. ในกรณีที่ผู้ซื้อรายใหม่ และไม่แน่ใจในคุณภาพของสินค้าของท่าน ท่านควรเจรจาต่อรองอย่างไร และใช้วิธีการชำระเงินอย่างไร',
        choice:[
            'เสนอให้ผู้ซื้อร้องขอ Inspection Certificate และใช้วิธีการชำระเงินแบบ Letter of Credit ',
            'เสนอให้ผู้ซื้อร้องขอ Inspection Certificate และใช้วิธีการชำระเงินแบบ ADVANCE PAYMENT',
            'เสนอให้ผู้ซื้อร้องขอ Inspection Certificate และใช้วิธีการชำระเงินแบบ O/A'
        ],
        picAnswer:{
            q1:'popup9.png',
            q2:'',
            q3:''
        },
        answer:1
    },
    {
        no:10,
        step:[],
        question:'10. หากท่านมีความกังวลว่าผู้ซื้อปฎิเสธการชำระเงิน หลังจากที่ท่านทำการส่งสินค้า ท่านควรทำอย่างไรเพื่อลดความเสี่ยง',
        choice:[
            'ทำประกันการส่งออก',
            'ผู้ซื้อจ่ายค่ามัดจำสินค้าก่อน 50%',
            'ขอกู้เงินทุนหมุนเวียนเพิ่ม'
        ],
        picAnswer:{
            q1:'popup10.png',
            q2:'',
            q3:''
        },
        answer:1
    },
    {
        no:11,
        step:[],
        question:'11. พฤติกรรมของผู้ซื้อคนใดที่ต้องระมัดระวังว่าอาจจะเป็นการหลอกลวง',
        choice:[
            'Mr. Ode ติดต่อขอสั่งซื้อน้ำมันปาล์ม  30 ตู้คอนเทนเนอร์ตั้งแต่ครั้งแรกที่ทำการค้า',
            'Mr. Chen ต้องการให้ส่ง Quotation พร้อมรายละเอียดของสินค้า และมาตรฐานสินค้าที่มี',
            'Ms. Monika ขอเจรจาเปลี่ยนวิธีการชำระเงินจาก Advance Payment เป็น D/A'
        ],
        picAnswer:{
            q1:'popup11.png',
            q2:'',
            q3:''
        },
        answer:1
    },
    {
        no:12,
        step:[],
        question:'12. ใน Incoterms แบบ CIF จุดส่งมอบสินค้าอยู่ ณ จุดใด',
        choice:[
            'หน้าโกดังผู้ขาย',
            'ท่าเรือต้นทาง',
            'ท่าเรือปลายทาง'
        ],
        picAnswer:{
            q1:'',
            q2:'popup12.png',
            q3:''
        },
        answer:2
    },
    {
        no:13,
        step:[],
        question:'13. ค่าใช้จ่ายใดที่ผู้ขายไม่ต้องรับผิดชอบ หากซื้อขายกันใน Incoterms FOB',
        choice:[
            'ค่าขนของจากโรงงานขึ้นรถบรรทุก',
            'ค่าขนสินค้าลงเรือที่ท่าต้นทาง',
            'ค่าประกันความเสียหายของสินค้าจากการขนส่งทางทะเล'
        ],
        picAnswer:{
            q1:'',
            q2:'',
            q3:'popup13.png'
        },
        answer:3
    },
    {
        no:14,
        step:[],
        question:'14. ข้อใดไม่เป็นข้อมูลสำคัญในใบคำสั่งซื้อ (Purchase order) เพื่อเป็นเอกสารประกอบการขอสินเชื่อจากธนาคาร',
        choice:[
            'ใบจดทะเบียนบริษัทของผู้ส่งออกสินค้าและผู้ซื้อสินค้า',
            'วันที่ส่งมอบสินค้า',
            'วิธีการชำระเงิน (L/C, D/P, D/A, O/A)'
        ],
        picAnswer:{
            q1:'popup14.png',
            q2:'',
            q3:''
        },
        answer:1
    },
    {
        no:15,
        step:[],
        question:'15. การซื้อขายแบบ L/C ธนาคารผู้เปิด L/C จะชำระเงินให้แก่ผู้ส่งออกเมื่อใด',
        choice:[
            'เมื่อผู้ส่งออกส่งสินค้าออกตาม L/C เรียบร้อยแล้ว ',
            'เมื่อผู้ส่งออกทำเอกสารส่งออกตาม L/C เรียบร้อยแล้วและยื่นต่อธนาคาร',
            'เมื่อผู้ส่งออกส่งสินค้า และยื่นเอกสารถูกต้อง ครบถ้วนตามเงื่อนไขของ L/C'
        ],
        picAnswer:{
            q1:'',
            q2:'',
            q3:'popup15.png'
        },
        answer:3
    },
    {
        no:16,
        question:'16. ในกรณีที่ Incoterms เป็นแบบ CFR หากสินค้าได้รับความเสียหายในระหว่างเดินเรือ เนื่องจากเรือต้องฝ่าพายุซึ่งถือเป็นเหตุสุดวิสัย ผู้ที่ต้องรับความเสียหายนั้นคือใคร',
        choice:[
            'บริษัทประกัน',
            'ผู้ซื้อ',
            'ผู้ขาย'
        ],
        picAnswer:{
            q1:'',
            q2:'popup16.png',
            q3:''
        },
        step:[],
        answer:2
    }
]