import Vue from "vue";

Vue.mixin({
  methods: {
    alertFailError() {
      this.$alert(
        "Oops! something went wrong. Please contact admin or try again later",
        {
          confirmButtonText: "OK",
          type: "error",
        }
      );
    },
    alertCatchError(e) {
      this.$alert(`${e}`, {
        confirmButtonText: "OK",
        type: "error",
      });
    },
  },
});
