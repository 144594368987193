import Oidc, { WebStorageStateStore } from "oidc-client";
const AUTH_DOMAIN = process.env.VUE_APP_OIDC_SERVE;
// const AUTH_DOMAIN = "https://exim1uat.exim.go.th/auth";
let host = process.env.VUE_APP_URL
let client_id = process.env.VUE_APP_CLIENT_ID
let client_secret = process.env.VUE_APP_CLIENT_SECRET
host = host?.slice( 0, host.length-1 );
const setting = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: AUTH_DOMAIN,
  client_id: client_id,
  client_secret: client_secret,
  redirect_uri: `${host}/callback`,
  response_type: "code",
  scope: "openid profile email offline_access",
  accessTokenExpiringNotificationTime: 300,
  automaticSilentRenew: true,
  post_logout_redirect_uri: `${host}`,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const AuthService = new Oidc.UserManager(setting);
export default AuthService;
