// @ts-nocheck
import {HTTP} from '@/service/axios'
var errorMsg = {
  data: {
    data: '',
  },
  success: false,
}
export default {
  methods: {
    directUrl(user, code = '') {
      let url = ''
      //console.log("code", code);
      if (user.role == 60) {
        url = '/user/list'
      } else if (user.role == 50) {
        url = '/log-system'
      } else if (user.role == 40) {
        url = '/refer'
      } else if (user.recommendStatus <= 1) {
        url =
          typeof code != 'undefined' && code
            ? `/result?code=${this.$route.query.code}`
            : '/result'
      } else {
        url =
          typeof code != 'undefined' && code
            ? `/factor?code=${this.$route.query.code}`
            : '/factor'
      }
      this.$router.push(url).catch((err) => {})
    },
    async checkLogin(token, code = '') {
      let userData = await this.getUserById(token)
      if (userData.data.success) {
        this.$store.commit(
          'SET_USER',
          JSON.parse(JSON.stringify(userData.data.obj))
        )
        this.$store.commit('SET_TOKEN', this.token)
        this.directUrl(userData.data.obj, code)
      }
    },
    async signIn ( obj )
    {
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      let res = await HTTP.post('sign/in', obj).catch((e) => {
        errorMsg.data.data = e
        if (e == 'Error: Request failed with status code 401') {
          this.checkAuth()
        } else {
          if (e != 'Error: timeout of 120000ms exceeded') {
            this.alertCatchError(e)
          }
        }
        return errorMsg
      })
      if (res.data.success || !res.data.success) {
        return res
      } else {
        return errorMsg
      }
    },
    async getUserById(token) {
      let url = 'user'
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      let res = await HTTP.get(url, config).catch((e) => {
        errorMsg.data.data = e
        if (e == 'Error: Request failed with status code 401') {
          this.checkAuth()
        } else {
          if (e != 'Error: timeout of 120000ms exceeded') {
            this.alertCatchError(e)
          }
        }
        return errorMsg
      })

      if (res.data.success || !res.data.success) {
        return res
      } else {
        return errorMsg
      }
    },
    async getTokenUser ( token )
    {
      HTTP.defaults.headers.common['X-CSRF-TOKEN'] = this.$csrfToken
      let res = await HTTP.post(`check/user/from/ex1m`, {token: token}).catch(
        (e) => {
          errorMsg.data.data = e
          if (e == 'Error: Request failed with status code 401') {
            this.checkAuth()
          } else {
            if (e != 'Error: timeout of 120000ms exceeded') {
              this.alertCatchError(e)
            }
          }
          console.log('getTokenUser catch', e)
          return errorMsg
        }
      )

      if (res.data.success || !res.data.success) {
        return res
      } else {
        return errorMsg
      }
    },
    checkAuth() {
      this.$confirm('กรุณาเข้าสู่ระบบใหม่อีกครั้ง', {
        confirmButtonText: 'OK',
        showCancelButton: false,
        type: 'error',
      }).then(() => {
        setTimeout(() => {
          window.localStorage.eram.clear()
        }, 200)
        this.$store.commit('SIGNOUT')
        window.localStorage.removeItem('eram')
        delete HTTP.defaults.headers.common.Authorization
        window.location.href = '/'
        this.$router.push('/')
        setTimeout(() => {
          this.$router.go()
        }, 250)
      })
    },
  },
}
