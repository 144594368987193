import initState from "./initState";

export default {
  SET_USER_OBJECT: (state, payload) => {
    state.user[payload.key] = payload.value;
  },
  // SET_CSRF: (state, payload) => {
  //   state.csrfToken = payload;
  // },
  SIGNOUT: (state) => {
    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_BRANCH: (state) => {
    const initial = initState();
    state.branch = initial.branch;
  },
  SET_REFER: (state, payload) => {
    state.refer = payload;
  },
  SET_ASSESSMENT_LIST: (state, payload) => {
    state.assessmentList = payload;
  },
  SET_MEMBEROF: (state, payload) => {
    state.memberOfOrganize = payload;
  },
  SET_CATEGORY_LIST: (state, payload) => {
    state.categoryList = payload;
  },
  SET_QUESTION_LIST: (state, payload) => {
    state.questionList = payload;
  },
  SET_USER_OIDC: async (state, payload) => {
    state.useroidc = payload;
  },
  SET_USER: async (state, payload) => {
    state.user = payload;
  },
  SET_STEP: (state, payload) => {
    state.step = payload;
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  SET_CODE: (state, payload) => {
    state.codeKey = payload;
  },
  SET_OPEN_MENU: (state, payload) => {
    state.openMenu = payload;
  },
  SET_ROOM: (state, payload) => {
    state.room = payload;
  },
};
